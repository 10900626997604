<template>
  <v-card
    id="pricing-plan"
    class="text-center py-sm-15 py-5"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="8"
          class="mx-auto"
        >
          <h1 class="font-weight-medium mb-5">
            Pricing Plans
          </h1>
          <p class="mb-3">
            Choose the best plan to fit your needs.
          </p>

          <!--plan switch -->
          <div class="d-flex align-center justify-center">
            <span class="font-weight-semibold">Monthly</span>
            <v-switch
              v-model="status"
              value="annually"
              class="mx-3"
            ></v-switch>
            <span class="font-weight-semibold">Annually</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="mt-3 pb-15">
      <v-row>
        <v-col
          offset-sm="2"
          sm="8"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <v-row>
            <!-- basic -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                outlined
                class="text-center"
              >
                <v-card-text>
                  <!-- image -->
                  <div class="d-flex justify-center">
                    <img
                      :src="pricing.basicPlan.imgSrc"
                      class="mx-auto mt-3"
                    >
                  </div>

                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    {{ pricing.basicPlan.title }}
                  </h1>
                  <p>{{ pricing.basicPlan.subtitle }}</p>

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <sup class="text-sm text-primary">$</sup>
                      <span class="pricing-basic-value text-5xl primary--text font-weight-semibold">
                        {{ status ? pricing.basicPlan.yearlyPlan.perMonth: pricing.basicPlan.monthlyPrice }}
                      </span>
                      <sub class="pricing-duration text-sm mb-n3">/month</sub>
                    </div>
                    <small
                      v-if="status"
                      class="annual-pricing"
                    >USD {{ pricing.basicPlan.yearlyPlan.totalAnnual }} / year</small>
                  </div>
                <!--/ annual plan -->
                </v-card-text>

                <v-card-text>
                  <div class="pb-2">
                    <div
                      v-for="benefits in pricing.basicPlan.planBenefits"
                      :key="benefits"
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">{{ benefits }}</span>
                    </div>
                  </div>

                  <!-- plan button -->
                  <v-btn
                    outlined
                    block
                    color="success"
                  >
                    Your current plan
                  </v-btn>

                  <!-- <div class="pb-2">
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Extra Storage</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Videos Feature</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Legal Services</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Posterity Services</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Chat Messaging</span>
                    </div>
                  </div> -->
                </v-card-text>
              </v-card>
            </v-col>

            <!-- standard -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                outlined
                class="popular text-center"
              >
                <v-card-text>
                  <!-- chip -->
                  <div
                    v-show="pricing.standardPlan.popularPlan"
                    class="pricing-badge text-right mt-n3"
                  >
                    <v-chip
                      small
                      color="primary"
                      class="v-chip-light-bg primary--text font-weight-semibold"
                    >
                      Popular
                    </v-chip>
                  </div>

                  <!-- image -->
                  <div class="d-flex justify-center">
                    <img
                      :src="pricing.standardPlan.imgSrc"
                      class="mx-auto"
                    >
                  </div>

                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    {{ pricing.standardPlan.title }}
                  </h1>
                  <p>{{ pricing.standardPlan.subtitle }}</p>

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <sup class="text-sm text-primary">$</sup>
                      <span class="pricing-basic-value text-5xl primary--text font-weight-semibold">
                        {{ status ? pricing.standardPlan.yearlyPlan.perMonth: pricing.standardPlan.monthlyPrice }}
                      </span>
                      <sub class="pricing-duration text-sm mb-n3">/month</sub>
                    </div>
                    <small
                      v-if="status"
                      class="annual-pricing"
                    >USD {{ pricing.standardPlan.yearlyPlan.totalAnnual }} / year</small>
                  </div>
                <!--/ annual plan -->
                </v-card-text>

                <v-card-text>
                  <div class="pb-2">
                    <div
                      v-for="benefits in pricing.standardPlan.planBenefits"
                      :key="benefits"
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">{{ benefits }}</span>
                    </div>
                  </div>

                  <!-- plan button -->
                  <v-btn
                    block
                    color="primary"
                  >
                    Upgrade
                  </v-btn>

                  <!-- <div class="pb-2">
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Extra Storage</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Mailer Feature</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Legal Services</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">Professional Website</span>
                    </div>
                  </div> -->
                </v-card-text>
              </v-card>
            </v-col>

            <!-- enterprise -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                outlined
                class="text-center"
              >
                <v-card-text>
                  <!-- image -->
                  <div class="d-flex justify-center">
                    <img
                      :src="pricing.enterprisePlan.imgSrc"
                      class="mx-auto mb-3"
                    >
                  </div>

                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    {{ pricing.enterprisePlan.title }}
                  </h1>
                  <p>{{ pricing.enterprisePlan.subtitle }}</p>

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <sup class="text-sm text-primary">$</sup>
                      <span class="pricing-basic-value text-5xl primary--text font-weight-semibold">
                        {{ status ? pricing.enterprisePlan.yearlyPlan.perMonth: pricing.enterprisePlan.monthlyPrice }}
                      </span>
                      <sub class="pricing-duration text-sm mb-n3">/month</sub>
                    </div>
                    <small
                      v-if="status"
                      class="annual-pricing"
                    >USD {{ pricing.enterprisePlan.yearlyPlan.totalAnnual }} / year</small>
                  </div>
                <!--/ annual plan -->
                </v-card-text>

                <v-card-text>
                  <div class="pb-2">
                    <div
                      v-for="benefits in pricing.enterprisePlan.planBenefits"
                      :key="benefits"
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary">{{ benefits }}</span>
                    </div>
                  </div>

                  <!-- plan button -->
                  <v-btn
                    outlined
                    block
                    color="primary"
                  >
                    Upgrade
                  </v-btn>

                  <!-- <div class="pb-2">

                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary"> Extra Storage</span>
                    </div>
                    <div
                      class="d-flex align-start text-sm px-5 my-4 cursor-pointer text-left"
                    >
                      <v-icon
                        size="14"
                        class="me-2 mt-1"
                      >
                        {{ icons.mdiCheckboxBlankCircleOutline }}
                      </v-icon>
                      <span class="text-sm text--secondary"> Upgrade to Enterprise Plan</span>
                    </div>
                  </div> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn
      color="primary"
      class="mt-4 mt-lg-6"
    >
      Especial Plan - Talk with us
    </v-btn>
    <!-- pricing free trial -->
    <v-card-text class="pricing-free-trial my-16">
      <v-row>
        <v-col
          cols="12"
          md="10"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-space-between flex-column flex-md-row">
            <div class="text-center text-md-left mt-7">
              <p class="text-2xl font-weight-medium primary--text mb-2">
                Still not convinced? Start with a 30-day FREE trial!
              </p>
              <p class="text-base">
                You will get full access to with all the features for 30 days.
              </p>

              <v-btn
                color="primary"
                class="mt-4 mt-lg-6"
              >
                Start 30-day FREE trial
              </v-btn>
            </div>

            <!-- images -->
            <v-img
              contain
              height="278"
              max-width="250"
              src="@/assets/images/3d-characters/pose-fs-9.png"
              class="pricing-trial-img mx-auto"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <!--/ pricing free trial -->

    <v-card-text>
      <h2 class="text-2xl font-weight-medium mb-2">
        FAQ's
      </h2>
      <p>Let us help answer the most common questions.</p>
    </v-card-text>

    <v-card-text class="pricing-accordion mt-1">
      <v-row>
        <v-col
          cols="12"
          md="8"
          class="mx-auto text-left"
        >
          <v-expansion-panels v-model="pricingAccordion">
            <v-expansion-panel
              v-for="item in faqsList"
              :key="item.title"
            >
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.desc }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'
import store from './../../../store';
export default {
  setup() {
    const status = ref(false)
    const pricingAccordion = ref(0)
    // this.$store.commit('setLogin', true);
    const pricing = {
      basicPlan: {
        title: 'Personal',
        monthlyPrice: 0,
        currentPlan: true,
        popularPlan: false,
        subtitle: 'A simple start for everyone',
        imgSrc: require('@/assets/images/misc/pricing-tree-1.png'),
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planBenefits: [
          'My Pictures',
          'My Biography',
          'My Diary',
          'Standard Categories',
          '250 files or 500mb of data capacity (upgradable)',
          'Advertisement',
          'FAQ Support'
        ],
      },
      standardPlan: {
        monthlyPrice: 5,
        title: 'Network',
        popularPlan: true,
        currentPlan: false,
        subtitle: 'The plan to fit your conections',
        imgSrc: require('@/assets/images/misc/pricing-tree-2.png'),
        yearlyPlan: {
          perMonth: 4.50,
          totalAnnual: 54.00,
        },
        planBenefits: [
          'My Pictures',
          'My Bibliography',
          'My Diary',
          'My To do List',
          'My World',
          'Unlimited Categories',
          '1000 files or 1GB of data capacity (upgradable)',
          'Unlimited Sharing',
          'Portfolio / Resume',
          'Chat Messaging',
          'Videos',
          'Email Support'
        ],
      },
      enterprisePlan: {
        monthlyPrice: 10,
        popularPlan: false,
        currentPlan: false,
        title: 'Enterprise',
        subtitle: 'Solution for your company',
        imgSrc: require('@/assets/images/misc/pricing-tree-3.png'),
        yearlyPlan: {
          perMonth: 8.50,
          totalAnnual: 102,
        },
        planBenefits: [
          'Personal (Package included)',
          'Network (Package included)',
          'Unlimeted File upload with 10GB storage',
          'Professional Website',
          'Web3 / Blockchain Features (Coming soon)',
          'Legal Services',
          'Mailer Features',
          'Posterity Services',
          'Chat Messaging',
          'Email Support',
          'Chat Support'
        ],
      },
    }

    const faqsList = [
      {
        title: 'General settings',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Users',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Personal data',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
      {
        title: 'Advanced settings',
        desc: 'Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.',
      },
    ]
    store.commit('setLogin', true);
    return {
      status,
      pricing,
      faqsList,
      pricingAccordion,
      icons: {
        mdiCheckboxBlankCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" >
@import '../../../@core/preset/preset/mixins';
@import '../../../@core/preset/preset/variables';

#pricing-plan {
  margin-top: 30px;
  .popular {
    border-color: rgba(145, 85, 253, 0.3);
  }

  .pricing-free-trial {
    background-color: rgba(145, 85, 253, 0.04);
    position: relative;
    height: 14.625rem;
    .pricing-trial-img {
      position: relative;
      top: -3.5rem;
      right: 0;
    }
  }
  .pricing-accordion {
    .v-expansion-panels {
      .v-expansion-panel {
        box-shadow: none !important;
        margin-bottom: -1px;
        &::before {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  #pricing-plan {
    .pricing-free-trial {
      height: auto;
      .pricing-trial-img {
        bottom: -1.2rem;
        top: unset;
      }
    }
  }
}

@include theme--child(pricing-accordion) using ($material) {
  .v-expansion-panels {
    .v-expansion-panel {
      border: 1px solid map-deep-get($material, 'dividers');
    }
  }
}

.v-chip:not(.v-chip--outlined).primary {
  color: #fff !important;
}
</style>
